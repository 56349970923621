import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CheckBox from "../../../../styles/svg/id-icon.svg";

function FieldCreateEditCard({
  handleKeyDown,
  setTitle,
  setCreateBtnOpen,
  edit = false,
  value,
  minRows = 3,
  className = "create-card-textarea",
  ref = null,
  setOpenCreateSubtask,
}) {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
    if (value.length <= 255) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <FormControl fullWidth onClick={(e) => e.stopPropagation()} ref={ref}>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="What needs to be done?"
        minRows={minRows} // Set the minimum number of rows
        className={`${className} ${error ? "create-card-error-textarea" : ""}`}
        defaultValue={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={setCreateBtnOpen}
        autoFocus
        ref={ref}
      />

      {!edit && (
        <InputAdornment position="end">
          <img src={CheckBox} alt="checkbox" className=" mb-6" />
          <ExpandMoreIcon
            className="mb-6"
            onClick={() => setOpenCreateSubtask(false)}
          />
        </InputAdornment>
      )}
      {error && (
        <Typography
          variant="body2"
          color="error"
          className="card-error-font-size"
        >
          Character limit exceeded! Maximum 255 characters allowed.
        </Typography>
      )}
    </FormControl>
  );
}

export default FieldCreateEditCard;
