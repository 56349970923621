import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Menu, Popover, Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import VectorArchived from "../../../../styles/svg/archive-btn-icon.svg";
import restoreArchiveIcon from "../../../../styles/svg/restore-board.svg";
import feedIcon from "../../../../styles/svg/feeds-icon.svg";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { archiveBoard } from "../../../../sevices/apiBoards";
import SeeWorklog from "../BoardHeader/seeWorklog/SeeWorklog";
import { useParams, useSearchParams } from "react-router-dom";
import { objectToFormData } from "../../../../utils/helpers";
import { changeExpand } from "../../../../redux/boardListSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import boardHeaderWorklogIcon from "../../../../styles/svg/worklogIcon.svg";
import boardHeaderuploadCSVIcon from "../../../../styles/svg/boardHeaperUploadCSV.svg";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { addAllBoard, setMyTaskUser } from "../../../../redux/SearchSlice";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { useUsers } from "../../../../utils/reactQuery/users";
import UploadCSV from "./uploadCSV/UploadCSV";
import { useResponsive } from "../../../../hooks/useResponsive";
import MergeBoard from "../mergeBoard/MergeBoard";
import mergeBoardIcon from "../../../../styles/svg/mergeBoardIcon.svg";

function Archive({ isOwner, isAdmin, boardId }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", id]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCSV, setOpenCSV] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useUsers();
  const isArchive = cachedData?.data?.data?.responseData?.is_archive;
  const isMobile = useResponsive("down", "md");
  const [epics, updateSearchParams, removeSearchParam] =
    useCustomSearchParams("epic");
  const [openMergeBoard, setOpenMergeBoard] = useState(false);
  // const workflowId = cachedData?.data?.data?.responseData?.workflow_id;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setArchiveDialog(false);
  };

  const handleCSV = () => {
    setOpenCSV(true);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const { mutate: archiveMutate } = useMutation({
    mutationFn: archiveBoard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      dispatch(changeExpand(isArchive ? "panel1" : "panel2"));
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      setLoading(false);
      toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  console.log("epics--", epics);

  const allUser = users?.data.data.responseData.filter((el) => el.active);
  const handleNavigate = (boardNumber) => {
    dispatch(setAllGroupUser([{ team: "Multiple", users: allUser }]));
    dispatch(addAllBoard([]));
    const today = new Date();
    const sevenDaysAgo = new Date();

    // Subtract 7 days from the current date
    sevenDaysAgo.setDate(today.getDate() - 7);

    const date = {
      startDate: sevenDaysAgo,
      endDate: today,
      key: "selection",
    };

    // navigate("/my-task");
    const newSearchParams = new URLSearchParams({
      tab: "worklog",
      board: boardNumber,
      epic: epics,
      date: JSON.stringify(date),
    });
    // dispatch(setAllGroupUser([]));
    navigate(`/my-task?${newSearchParams.toString()}`);
    // newSearchParams.set(key, value);
    // setSearchParams(newSearchParams);
  };

  const handleNavigateFeed = (boardId) => {
    navigate(`/feeds/${boardId}`);
  };

  const callback = () => {
    const data = objectToFormData({ is_archive: !isArchive, board_id: id });
    setLoading(true);
    archiveMutate({ id, data });
  };

  const handleMergeBoard = () => {
    setOpenMergeBoard(true);
  };

  return (
    <>
      {isMobile ? (
        <>
          {(isAdmin || isOwner) && (
            <>
              {" "}
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                  color: "rgba(33, 43, 54, 1)",
                  fontWeight: "400",
                  fontSize: "14px",
                  backgroundColor: "rgb(242, 244, 246)",
                  padding: isMobile && "0px 8px !important",
                  minHeight: isMobile && "38px",
                  marginBottom: "8px",
                }}
                onClick={() => setArchiveDialog(true)}
              >
                <img
                  src={isArchive ? restoreArchiveIcon : VectorArchived}
                  alt="Not Found"
                  className="mr-8"
                />
                {isArchive ? "Restore Board" : "Archive the board"}
              </MenuItem>
              {/* <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />{" "} */}
            </>
          )}
          {(isAdmin || isOwner) && (
            <>
              {" "}
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                  color: "rgba(33, 43, 54, 1)",
                  fontWeight: "400",
                  fontSize: "14px",
                  backgroundColor: "rgb(242, 244, 246)",
                  padding: isMobile && "0px 8px !important",
                  minHeight: isMobile && "38px",
                  marginBottom: "8px",
                }}
                onClick={() => handleNavigateFeed(boardId)}
              >
                <img src={feedIcon} alt="Not Found" className="mr-8" />
                Feeds
              </MenuItem>
            </>
          )}
          <MenuItem
            sx={{
              textTransform: "capitalize",
              color: "rgba(33, 43, 54, 1)",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "rgb(242, 244, 246)",
              padding: isMobile && "0px 8px !important",
              minHeight: isMobile && "38px",
              marginBottom: "8px",
            }}
            onClick={() => handleNavigate(boardId)}
          >
            <img
              src={boardHeaderWorklogIcon}
              alt="Not Found"
              className="mr-8"
              style={{ paddingLeft: "8px" }}
            />
            {"See Worklogs"}
            {/* <SeeWorklog/> */}
          </MenuItem>

          <MenuItem
            sx={{
              textTransform: "capitalize",
              color: "rgba(33, 43, 54, 1)",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "rgb(242, 244, 246)",
              padding: isMobile && "0px 8px !important",
              minHeight: isMobile && "38px",
            }}
            onClick={() => handleCSV()}
          >
            <img
              src={boardHeaderuploadCSVIcon}
              alt="Not Found"
              className="mr-8"
              style={{ paddingLeft: "8px" }}
            />
            {"Upload CSV"}
          </MenuItem>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="white-button archive-button"
          onClick={handleClick}
          sx={{
            height: "38px",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}
        >
          <MoreHorizIcon />
        </Button>
      )}

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        className="archive-popover"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        {/* <Button
          sx={{ textTransform: "capitalize", color: "rgba(33, 43, 54, 1)" }}
          onClick={() => setArchiveDialog(true)}
        >
          <img src={isArchive ? restoreArchiveIcon : VectorArchived} alt="Not Found" className="mr-8" />
          {isArchive ? "Restore Board" : "Archive the board"}
        </Button>
        <SeeWorklog/> */}
        {/* <MenuItem> */}
        {(isAdmin || isOwner) && (
          <>
            {" "}
            <MenuItem
              sx={{
                textTransform: "capitalize",
                color: "rgba(33, 43, 54, 1)",
                fontWeight: "400",
                fontSize: "14px",
              }}
              onClick={() => setArchiveDialog(true)}
            >
              <img
                src={isArchive ? restoreArchiveIcon : VectorArchived}
                alt="Not Found"
                className="mr-8"
              />
              {isArchive ? "Restore Board" : "Archive the board"}
            </MenuItem>
            <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />{" "}
          </>
        )}
        {(isAdmin || isOwner) && (
          <>
            {" "}
            <MenuItem
              sx={{
                textTransform: "capitalize",
                color: "rgba(33, 43, 54, 1)",
                fontWeight: "400",
                fontSize: "14px",
              }}
              onClick={() => handleNavigateFeed(boardId)}
            >
              <img src={feedIcon} alt="Not Found" className="mr-8" />
              Feed
            </MenuItem>
            <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />{" "}
          </>
        )}
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => handleNavigate(boardId)}
        >
          <img
            src={boardHeaderWorklogIcon}
            alt="Not Found"
            className="mr-8"
            style={{ paddingLeft: "8px" }}
          />
          {"See Worklogs"}
          {/* <SeeWorklog/> */}
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => handleCSV()}
        >
          <img
            src={boardHeaderuploadCSVIcon}
            alt="Not Found"
            className="mr-8"
            style={{ paddingLeft: "8px" }}
          />
          {"Upload CSV"}
        </MenuItem>
        {(isAdmin || isOwner) && (
          <>
            {" "}
            <MenuItem
              sx={{
                textTransform: "capitalize",
                color: "rgba(33, 43, 54, 1)",
                fontWeight: "400",
                fontSize: "14px",
              }}
              onClick={handleMergeBoard}
            >
              <img
                src={mergeBoardIcon}
                alt="Not Found"
                className="mr-8"
                style={{ paddingLeft: "8px" }}
              />
              Merge Board
            </MenuItem>
            <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />{" "}
          </>
        )}
      </Menu>

      {archiveDialog && (
        <ConfirmationDialog
          open={archiveDialog}
          handleClose={handleCloseDialog}
          message={
            isArchive
              ? "Are you sure you want to restore the board ?"
              : "Are you sure you want to archive this board? Archiving the board will hide it from view and move it to the archive section. You can always restore it later if needed. Please confirm your action."
          }
          callback={callback}
          loading={loading}
          btnTitle={isArchive ? "Restore" : "Archive"}
          title={isArchive ? "Restore Board" : "Archive Board"}
        />
      )}
      {openCSV && <UploadCSV openCSV={openCSV} closeCSV={setOpenCSV} />}
      {openMergeBoard && (
        <MergeBoard
          close={setOpenMergeBoard}
          open={openMergeBoard}
          currentBoard={cachedData}
        />
      )}
    </>
  );
}

export default Archive;
