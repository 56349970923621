import { Navigate, Outlet, useLocation } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import { useAssets } from "../../utils/reactQuery/domainAssets";
import Loader from "../../components/Loader";

const Private = () => {
  const location = useLocation();
   const { isLoading, error, icon, isError, logo } = useAssets();
   if (isLoading) {
     return (
       <div className="loader-center">
         <Loader color={"black"} />
       </div>
     );
   }

    if (
      isError  &&
      error.response?.status == "404" &&
      !error.response?.data?.tenant_found
    ) {
      return <Navigate to="/not-found" />;
    }
    
  const currentUrl = `${location.pathname}${location.search}`;

  const auth =
    localStorageInstance.getItem("token") &&
    localStorageInstance.getItem("userId");

  localStorageInstance.setItem({ key: "redirectLocation", value: currentUrl });
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default Private;
