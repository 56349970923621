import React, { useEffect, useState, useMemo, useCallback } from "react";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./tableStyle.css";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import TableToolBar from "./TableToolBar";
import TableHeaderComponent from "./TableHeader";
import TableRowComponent from "./TableRow";
import TablePaginationComponent from "./TablePagination";
import LoadingTableRow from "./LoadingTableRow";
import NoTableDataRow from "./NoTableDataRow";
import DraggableColumn from "./DraggableColumn";
import DraggableRow from "./DraggableRow";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const GlobalTableComponent = ({
  headers = [],
  data = [],
  subHeader,
  align = "left",
  noDataMessage = "No Record Found",
  defaultSortBy = "id",
  isLoading = false,
  isSearch = false,
  isCollapsible = false,
  isCheckbox = false,
  rowCount = 5,
  isPagination = false,
  isDraggableRow = false,
  isDraggableColumn = false,
  searchProps,
  searchInputClass,
  paginationClass,
  paginationProps,
  noDataClass,
  headerClass,
  tableClass,
  loadingClass,
  loadingProps,
  tableContainerClass,
  subHeaderClass,
  toolbarClass,
  tableBoxClass,
  isToggleColumn = false,
  isDataExport = false,
  isToolBar = false,
  toggleColumnButtonProps,
  selectAllCheckBoxProps,
  isColumnPinned = false,
  loader,
  handleExpandedData,
  isHeaderHidden = false,
  isHiddenCheckBoxIcon = false,
  isHiddenDraggableRowIcon = false,
  isHiddenRowExpandIcon = false,
  subTableProps,
  handleColumOrder,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState(data);
  const [rowPerPage, setRowsPerPage] = useState(rowCount);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(defaultSortBy);
  const [selected, setSelected] = React.useState([]);
  const [headerColumn, setHeaderColumn] = useState(headers);
  const [expandedRowId, setExpandedRowId] = useState(null);
  useEffect(() => {
    if (headers) {
      setHeaderColumn(headers);
    }
  }, [headers]);

  // console.log("COLUMHeader", headerColumn);
  const handleShowHideColumn = useCallback(
    (id) => {
      setHeaderColumn(
        headerColumn.map((header) => {
          if (header.id === id) {
            return { ...header, isHidden: !header.isHidden };
          } else {
            return header;
          }
        })
      );
    },
    [headerColumn]
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = tableData.map((n) => n?.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [tableData]
  );

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowSelect = useCallback(
    (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const handleChange = useCallback(
    (event) => {
      const searchTerm = event.target.value;
      const filteredData = tableData
        ?.filter((data) =>
          Object.keys(data).some((key) =>
            String(data[key]).toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
        .slice(page * rowPerPage, page * rowPerPage + rowPerPage);
      setTableData([...filteredData]);

      if (searchTerm == "") {
        setTableData(
          [...data]
            .sort(getComparator(order, orderBy))
            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
        );
      }
    },
    [tableData]
  );

  const handleExportData = useCallback(() => {
    const selectedData = data.filter((row) => selected.includes(row.id));
  }, [selected]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [page]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); // Reset to first page
    },
    [rowPerPage]
  );

  const visibleRows = React.useMemo(() => {
    if (!data) return [];

    const sortedData = [...data].sort(getComparator(order, orderBy));
    return isPagination
      ? sortedData.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
      : sortedData;
  }, [data, order, orderBy, page, rowPerPage, isPagination]);

  useEffect(() => {
    setTableData(visibleRows);
  }, [visibleRows]);

  const handleRowExpand = (id) => {
    if (id === expandedRowId) {
      setExpandedRowId(null);
    } else {
      setExpandedRowId(id);
    }
  };

  return (
    <TableContainer
      component={Paper}
      className={`global-table-container ${tableContainerClass}`}
    >
      {isToolBar && (
        <TableToolBar
          selectedCount={selected.length}
          handleExportData={handleExportData}
          headers={headerColumn} // changetabletable
          handleShowHideColumn={handleShowHideColumn}
          toolbarClass={toolbarClass}
          isSearch={isSearch}
          searchInputClass={searchInputClass}
          handleChange={handleChange}
          searchProps={searchProps}
          isToggleColumn={isToggleColumn}
          isDataExport={isDataExport}
          toggleColumnButtonProps={toggleColumnButtonProps}
        />
      )}

      <Box className={`global-table-box ${tableBoxClass}`}>
        <MUITable
          className={`global-table ${tableClass}`}
          stripe={"odd"}
          aria-label="simple table"
          {...props}
        >
          {!isHeaderHidden && (
            <DraggableColumn
              isDraggableColumn={isDraggableColumn}
              headerColumn={headerColumn}
              setHeaderColumn={setHeaderColumn}
              handleColumOrder={handleColumOrder}
            >
              <TableHeaderComponent
                headers={headerColumn} // change
                headerClass={headerClass}
                isCheckbox={isCheckbox}
                isCollapsible={isCollapsible}
                isDraggableRow={isDraggableRow}
                isDraggableColumn={isDraggableColumn}
                handleSelectAllClick={handleSelectAllClick}
                selectedCount={selected.length}
                dataCount={tableData?.length}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
                selectAllCheckBoxProps={selectAllCheckBoxProps}
                isColumnPinned={isColumnPinned}
              />
            </DraggableColumn>
          )}
          <DraggableRow
            setTableData={setTableData}
            isDraggableRow={isDraggableRow}
            tableData={tableData}
          >
            <TableBody>
              {isLoading && (
                <LoadingTableRow
                  loadingClass={loadingClass}
                  loadingProps={loadingProps}
                  colSpan={
                    isCheckbox && isDraggableRow
                      ? headers.length + 3
                      : isCheckbox || isDraggableRow
                      ? headers.length + 2
                      : headers.length + 1
                  }
                />
              )}

              {!isLoading &&
                tableData &&
                tableData.length > 0 &&
                tableData.map((row, index) => {
                  const isItemSelected = selected.includes(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowComponent
                      key={row?.id}
                      isCollapsible={isCollapsible}
                      isItemSelected={isItemSelected}
                      isCheckbox={isCheckbox}
                      isDraggableRow={isDraggableRow}
                      labelId={labelId}
                      row={row}
                      handleRowSelect={handleRowSelect}
                      subHeaderClass={subHeaderClass}
                      subHeader={
                        isHeaderHidden === true ? headerColumn : subHeader
                      }
                      order={order}
                      orderBy={orderBy}
                      isColumnPinned={isColumnPinned}
                      headers={headerColumn} // change
                      handleExpandedData={handleExpandedData}
                      isHiddenCheckBoxIcon={isHiddenCheckBoxIcon}
                      isHiddenDraggableRowIcon={isHiddenDraggableRowIcon}
                      isHiddenRowExpandIcon={isHiddenRowExpandIcon}
                      subTableProps={subTableProps}
                      tableData={tableData}
                      handleRowExpand={handleRowExpand}
                      isExpandable={row.id == expandedRowId ? true : false}
                    />
                  );
                })}

              {!isLoading && tableData?.length == 0 && (
                <NoTableDataRow
                  noDataClass={noDataClass}
                  noDataMessage={noDataMessage}
                  // rowSpanCount = {rowPerPage}
                  colSpanCount={
                    isCheckbox && isDraggableRow
                      ? headers.length + 3
                      : isCheckbox || isDraggableRow
                      ? headers.length + 2
                      : headers.length + 1
                  }
                />
              )}
            </TableBody>
          </DraggableRow>
        </MUITable>
      </Box>

      {isPagination && (
        <TablePaginationComponent
          rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
          component="div"
          rowCount={data.length}
          rowsPerPage={rowPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default GlobalTableComponent;

/**
 *
 * rows          done
 * column        done
 * header bg     done
*
*
 * defaultsort   done
 * isLoading     done
 * sort asc/desc done
 * sortspecificcolumn done
    key: "fat",
    label: "Fat
 * stripped
 * row height
 * pagination  done
 * filter   done
 * sorting  done
 *
 *
 * table stripe
 * Sorting on all column   done
 * Collapsible table   done
 * Sorting & selecting   done
 *
 *
 *
 * Scrollable Table
 * Correct Row Expand on SubTable
 * Drag & Drop Column
 * Show/hide column
 * Pin Column (FIX Column)
 * Header FIlter (Input, select)
 * Render DIfferent data
 * Header Grouping
 * Column Grouping
 * Row Grouping
 * Editable
 * Action Key (Button)
 *
 *
 */
