import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCheckpointIcon from "../../../styles/svg/add_checkpoint.svg";
import MuiTable from "../../../pages/admin/board/components/BoardTable/MuiTbale";
import ToggleInput from "../../Switch";
import {
  useCheckpoints,
  useEpicThroughId,
} from "../../../utils/reactQuery/epics";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import ReactQuill from "react-quill";
import { removeHtmlTagsAndEntities } from "../../../utils/helpers";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import AddCheckpoint from "../addCheckpoint";
import {
  deleteEpic,
  updateCheckpoint,
  updateEpicDetails,
} from "../../../sevices/apiEpic";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import MilestoneHistory from "../milestoneHistory";
import deleteIcon from "../../../styles/svg/deleteMilestone.svg";
import { isAdmin } from "../../../utils/constants";
import { useResponsive } from "../../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditMIlestone({ open, handleClose, title, boardName }) {
  const [description, setDescription] = React.useState("");
  const { id } = useParams();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const [startDate, setStartDate] = React.useState();
  const [targetDate, setTargetDate] = React.useState();
  const [showEditor, setShowEditor] = React.useState(false);
  const { epics } = useEpicThroughId(milestone);
  const { checkpoint } = useCheckpoints(milestone);
  const [addCheckpoint, setCheckpoint] = React.useState(false);
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = React.useState("");
  const [checkpointName, setCheckpointName] = React.useState("");
  const isMobile = useResponsive("down", "md");
  React.useEffect(() => {
    if (epics) {
      setDescription(epics?.data?.data?.responseData.epic.description);
      setStartDate(
        epics?.data?.data?.responseData.epic.start_date
          ? dayjs(
              moment(epics?.data?.data?.responseData.epic.start_date).format(
                "MM/DD/YYYY"
              )
            )
          : null
      );
      setTargetDate(
        epics?.data?.data?.responseData.epic.target_release_date
          ? dayjs(
              moment(
                epics?.data?.data?.responseData.epic.target_release_date
              ).format("MM/DD/YYYY")
            )
          : null
      );
    }
  }, [epics]);

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: updateEpicDetails,
    onSuccess: (res) => {
      toast.success(res.data.message);
      setShowEditor(false);
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["cards", id],
      });
    },
    onError: (error) => {
      console.error("Error updated epic details", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // handleClose();
    },
  });

  const handleDescription = () => {
    updateCardmutate({
      epic_id: milestone,
      board_id: id,
      data: {
        description: description,
      },
    });
  };

  const handleTargetdate = (newValue) => {
    updateCardmutate({
      epic_id: milestone,
      board_id: id,
      data: {
        target_release_date: newValue?.$d
          ? moment(newValue.$d).format("YYYY-MM-DD")
          : "",
      },
    });
  };

  const handleStartdate = (newValue) => {
    updateCardmutate({
      epic_id: milestone,
      board_id: id,
      data: {
        start_date: newValue?.$d
          ? moment(newValue.$d).format("YYYY-MM-DD")
          : "",
      },
    });
  };

  const handleCheckBox = (event, value, id) => {
    updateCheckpoint({
      checkpoint_id: id,
      data: {
        is_done: value,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: ["checkpoints", milestone],
        });
        queryClient.invalidateQueries({
          queryKey: ["boards"],
        });
      })
      .catch((err) => {
        console.log("checkpint isDone error == ", err);
      });
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleDelete = () => {
    deleteEpic(milestone, id)
      .then((res) => {
        toast.success(res.data.message);
        handleClose();
        updateSearchParams("milestone", "");
        queryClient.invalidateQueries({
          queryKey: ["epic", id],
        });
      })
      .catch((err) => {
        console.log("delete milestone error == ", err);
        toast.error(err.response.data.message);
      });
  };

  const handleNameKeyDown = (e) => {
    if (e.key === "Enter") {
      updateCardmutate({
        epic_id: milestone,
        board_id: id,
        data: {
          epic_name: name,
        },
      });
    }
  };

  const handleComplete = () => {
    updateCardmutate({
      epic_id: milestone,
      board_id: id,
      data: {
        completed: !epics?.data?.data.responseData.epic.completed,
      },
    });
  };

  const handleCheckName = (e, CheckId) => {
    if (e.key === "Enter") {
      updateCheckpoint({
        checkpoint_id: CheckId,
        data: {
          checkpoint_name: e.target.value,
        },
      })
        .then((res) => {
          toast.success(res.data.message);
          queryClient.invalidateQueries({
            queryKey: ["checkpoints", milestone],
          });
          queryClient.invalidateQueries({
            queryKey: ["epic_id", milestone],
          });
          queryClient.invalidateQueries({
            queryKey: ["epic", id],
          });
        })
        .catch((err) => {
          console.log("checkpint isDone error == ", err);
        });
    }
  };

  const isEditable = isAdmin() || boardName?.data?.responseData?.is_owner;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={!isMobile}
        fullScreen={isMobile}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: isMobile ? "" : "8px",
            maxWidth: "1180px",
            height: isMobile ? "100vh" : "90vh",
            margin: isMobile ? "auto" : "",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="edit-worklog-title"
        >
          <Stack direction="row" justifyContent="space-between">
            <TextField
              variant="standard"
              sx={{ flexGrow: 1 }}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleNameKeyDown}
              // disabled={isAdmin() ? false : true}
              InputProps={{
                disableUnderline: true, // <== added this
                style: {
                  height: 32,
                  border: "none",
                  fontSize: isMobile ? "16px" : "20px",
                  fontWeight: 700,
                  color: "#1B3E61",
                  pointerEvents: isEditable ? "" : "none",
                },
              }}
              defaultValue={epics?.data?.data?.responseData.epic.epic_name}
            />

            {isEditable ? (
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#fff",
                  padding: "6px 16px",
                  border: "1px solid #919EAB33",
                  borderRadius: "4px",
                  marginRight: "40px",
                  marginTop: "-3px",
                  cursor: "pointer",
                }}
                alignItems="center"
                onClick={handleDelete}
              >
                <img src={deleteIcon} alt="delete" />
                <Typography fontWeight={500} fontSize={14}>
                  Delete
                </Typography>
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ height: "100%" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ flexGrow: 1, width: isMobile ? "100%" : "" }}>
                <Grid container spacing={2}>
                  <Grid item xs={isMobile ? 12 : 8} sx={{ overflowY: "auto" }}>
                    <Box className="left-side-milestone">
                      <Typography className="milestone-lable">
                        Description<span className="star">*</span>
                      </Typography>

                      {showEditor ? (
                        <Box>
                          <ReactQuill
                            value={description}
                            onChange={(value) =>
                              removeHtmlTagsAndEntities(value).length === 0
                                ? setDescription("")
                                : setDescription(value)
                            }
                            style={{ height: isMobile ? "170px" : "438px" }}
                          />
                          <div
                            className="edit-milestone-text-editor-btn-div"
                            // style={{ marginTop: "52px" }}
                          >
                            <Button
                              onClick={handleDescription}
                              className="text-editor-btn1"
                              variant="contained"
                            >
                              <div className="text-editor-btn-content">
                                Save
                              </div>
                            </Button>
                            <Button
                              onClick={() => setShowEditor(false)}
                              className="text-editor-btn2"
                              variant="outlined"
                            >
                              <div className="text-editor-btn-content">
                                Cancel
                              </div>
                            </Button>
                          </div>
                        </Box>
                      ) : (
                        // <TextField
                        //   onClick={() =>
                        //     isEditable ? setShowEditor(true) : ""
                        //   }
                        //   multiline
                        //   rows={isMobile ? 8 : 20}
                        //   fullWidth
                        //   value={
                        //     description
                        //       ? removeHtmlTagsAndEntities(description)
                        //       : ""
                        //   }
                        // >
                        //   test
                        // </TextField>
                        <div
                          className="milestone-description"
                          onClick={() =>
                            isEditable ? setShowEditor(true) : ""
                          }
                          style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: description ? description : "",
                          }}
                        ></div>
                      )}
                    </Box>
                    {isMobile ? "" : <MilestoneHistory />}
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? "" : 2.4}>
                    {isMobile ? (
                      <Typography fontSize={"16px"} fontWeight={700}>
                        PROPERTIES
                      </Typography>
                    ) : (
                      ""
                    )}

                    <Grid container className="sidebar-block">
                      <Grid item className="sidebar-heading">
                        <div className="sidebar-heading-content"></div>
                      </Grid>
                      <Grid item className="sidebar-details">
                        <Grid item className="sidebar-details-content">
                          <Grid item className="sidebar-worklog">
                            <div className="sidebar-worklog-detail">
                              Details
                            </div>
                          </Grid>
                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Start Date</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <DatePicker
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "32px !important",
                                      backgroundColor: "#fff",
                                      width: "100%",
                                    },
                                  }}
                                  maxDate={
                                    targetDate
                                      ? targetDate
                                      : dayjs("2100-01-01")
                                  }
                                  disabled={isEditable ? false : true}
                                  value={startDate}
                                  onChange={handleStartdate}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="MM/DD/YYYY"
                                    />
                                  )}
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      variant: "outlined",
                                    },
                                  }}
                                />
                              </div>
                            </Grid>
                          </div>
                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Target Date</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <DatePicker
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "32px !important",
                                      backgroundColor: "#fff",
                                      width: "100%",
                                    },
                                  }}
                                  minDate={
                                    startDate ? startDate : dayjs("1900-01-01")
                                  }
                                  disabled={isEditable ? false : true}
                                  value={targetDate}
                                  onChange={handleTargetdate}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="MM/DD/YYYY"
                                    />
                                  )}
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      variant: "outlined",
                                    },
                                  }}
                                />
                              </div>
                            </Grid>
                          </div>
                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Total Cards</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <TextField
                                  disabled
                                  id="outlined-basic-input"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      height: 32,
                                      border: "none",
                                      backgroundColor: "#F2F4F6",
                                    },
                                  }}
                                  className="taskdetail-sidebar-right-col"
                                  size={"small"}
                                  defaultValue={
                                    epics?.data?.data.responseData.card_count
                                  }
                                  // variant="outlined"
                                  // onChange={handleInputHourChange}
                                  // onKeyDown={handleKeyDown}
                                  // onBlur={handleInputBlur}
                                  autoFocus
                                />
                              </div>
                            </Grid>
                          </div>
                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Allocated Hours</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <TextField
                                  disabled
                                  id="outlined-basic-input"
                                  style={{
                                    height: 30,
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                      border: "none",
                                      backgroundColor: "#F2F4F6",
                                    },
                                  }}
                                  fullWidth
                                  className="taskdetail-sidebar-right-col"
                                  size={"small"}
                                  defaultValue={
                                    epics?.data?.data.responseData
                                      .total_allocated_hours
                                  }
                                  // variant="outlined"
                                  // onChange={handleInputHourChange}
                                  // onKeyDown={handleKeyDown}
                                  // onBlur={handleInputBlur}
                                  autoFocus
                                />
                              </div>
                            </Grid>
                          </div>

                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Hours Spent</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <TextField
                                  disabled
                                  id="outlined-basic-input"
                                  InputProps={{
                                    style: {
                                      height: 32,
                                      border: "none",
                                      backgroundColor: "#F2F4F6",
                                    },
                                  }}
                                  fullWidth
                                  className="taskdetail-sidebar-right-col"
                                  size={"small"}
                                  defaultValue={
                                    epics?.data?.data.responseData
                                      .total_hours_spent
                                  }
                                  // variant="outlined"
                                  // onChange={handleInputHourChange}
                                  // onKeyDown={handleKeyDown}
                                  // onBlur={handleInputBlur}
                                  autoFocus
                                />
                              </div>
                            </Grid>
                          </div>

                          <div className="box">
                            <Grid item className="allocated-hours ">
                              <p>Hours Remaining</p>
                              <div
                                className="taskdetail-sidebar-right-col"
                                // onClick={handleAllocatedHoursInput}
                              >
                                <TextField
                                  disabled
                                  id="outlined-basic-input"
                                  fullWidth
                                  className="taskdetail-sidebar-right-col"
                                  size={"small"}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                      border: "none",
                                      backgroundColor: "#F2F4F6",
                                    },
                                  }}
                                  defaultValue={
                                    epics?.data?.data.responseData
                                      .total_remaining_hours
                                  }
                                  variant="outlined"
                                  // onChange={handleInputHourChange}
                                  // onKeyDown={handleKeyDown}
                                  // onBlur={handleInputBlur}
                                  autoFocus
                                />
                              </div>
                            </Grid>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mx={2}
                            >
                              <Typography fontSize={12}>Checkpoints</Typography>
                              {isEditable ? (
                                <img
                                  src={AddCheckpointIcon}
                                  alt="add checkpoint"
                                  width={20}
                                  onClick={() => setCheckpoint(true)}
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                            <Box m={1}>
                              <MuiTable
                                border={true}
                                minRowHeight={40}
                                initialRows={
                                  checkpoint
                                    ? checkpoint?.data?.data.responseData.map(
                                        (ele, idx) => {
                                          return {
                                            id: idx,
                                            ...ele,
                                            checkpoint_name: {
                                              checkpoint_name:
                                                ele.checkpoint_name,
                                              id: ele.checkpoint_id,
                                            },
                                            is_done: {
                                              is_done: ele.is_done,
                                              id: ele.checkpoint_id,
                                            },
                                          };
                                        }
                                      )
                                    : []
                                }
                                columns={[
                                  {
                                    field: "checkpoint_name",
                                    headerName: "Check",
                                    minWidth: 135,
                                    flex: 1,
                                    sortable: false,
                                    resizable: false,
                                    renderCell: (value) => (
                                      <span>
                                        <TextField
                                          variant="standard"
                                          // onChange={(e) => ""}
                                          onKeyDown={(e) => {
                                            e.stopPropagation();
                                            // e.preventDefault();
                                            handleCheckName(e, value.value.id);
                                          }}
                                          defaultValue={
                                            value.value.checkpoint_name
                                          }
                                          inputProps={{ maxLength: 30 }}
                                          InputProps={{
                                            disableUnderline: true, // <== added this
                                            style: {
                                              height: 32,
                                              border: "none",
                                              fontSize: "14px",
                                              // fontWeight: 700,
                                              // color: "#1B3E61",
                                            },
                                          }}
                                        />
                                      </span>
                                    ),
                                  },
                                  {
                                    field: "datetime",
                                    headerName: "Date",
                                    minWidth: 124,
                                    flex: 1,
                                    sortable: false,
                                    resizable: false,
                                    renderCell: (value) => (
                                      <span>
                                        {moment(value.value).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </span>
                                    ),
                                  },
                                  {
                                    field: "is_done",
                                    headerName: "Actions",
                                    minWidth: 50,
                                    flex: 1,
                                    sortable: false,
                                    resizable: false,
                                    renderCell: (value) => (
                                      <Checkbox
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 20, // Adjust the size of the checkbox
                                            pointerEvents: isEditable
                                              ? ""
                                              : "none",
                                          },
                                        }}
                                        disabled={isEditable ? false : true}
                                        checked={value.value.is_done}
                                        onChange={(e, val) =>
                                          handleCheckBox(e, val, value.value.id)
                                        }
                                      />
                                    ),
                                  },
                                ]}
                                footer={true}
                              />
                            </Box>
                            <Stack
                              direction="row"
                              ml={2}
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                pointerEvents: isEditable ? "" : "none",
                              }}
                            >
                              <Stack direction="row">
                                <Typography mr={1} fontSize={12}>
                                  Is Locked
                                </Typography>
                                <ToggleInput
                                  name={
                                    epics?.data?.data?.responseData.epic
                                      .epic_name
                                  }
                                  defaultValue={
                                    epics?.data?.data.responseData.epic
                                      .is_locked
                                  }
                                />
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography mr={1} fontSize={12}>
                                  Completed
                                </Typography>
                                <Checkbox
                                  checked={
                                    epics?.data?.data.responseData.epic
                                      .completed
                                  }
                                  onChange={() => handleComplete()}
                                />
                              </Stack>
                            </Stack>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {isMobile ? <MilestoneHistory /> : ""}
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "end",
            mb: 3,
            height: "0px",
            padding: "0px",
          }}
        ></DialogActions>
      </Dialog>
      <AddCheckpoint
        open={addCheckpoint}
        handleClose={() => setCheckpoint(false)}
      />
    </React.Fragment>
  );
}
