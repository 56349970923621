import Api from "./axios";
import { objectToFormData, parseLdapUrl } from "../utils/helpers";

export const updateTenantInfo = async (data) => {
  const { domain, company_name } = data;
  return await Api.put(
    `/update_tenant_variables?domain=${domain}&company_name=${company_name}`
  );
};

export const updateTenantLogos = async (data) => {
  return await Api.put(
    `/update_tenant_icons`, objectToFormData(data)
  );
};

export const updateTenantLDAP = async (data) => {
    const { ldap_url, ldap_user, ldap_base_dn, ldap_password } = data;
    const { host='',port='' } = ldap_url ? parseLdapUrl(ldap_url) : {};
  return await Api.put(
    `/update_tenant_ldap?ldap_host=${host}&ldap_port=${port}&ldap_user=${ldap_user}&ldap_base_dn=${ldap_base_dn}&ldap_password=${ldap_password}`
  );
};

export const getTenantInfo = async () => {
  return await Api.get(`/get_tenant_var`);
};


export const checkDomain = async (data) => {
  return await Api.get(
    `/check_tenant?domain=${
      data?.domain || ""
    }`
  );
};