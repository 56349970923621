import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "../../../../components/Avatar";
import { createAvtarName } from "../../../../utils/helpers";

export default function Team({ team, user, filteredItems }) {
  const dispatch = useDispatch();
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );

  const allTeams =
    team?.data.data.responseData?.map((team) => {
      return {
        ...team,
        users: team.users.filter((user) => user.active),
      };
    }) || [];

  const setAllTeam = () => {
    if (groupingUser.length >= allTeams.length) {
      dispatch(setAllGroupUser([]));
    } else {
      dispatch(setAllGroupUser(allTeams));
    }
  };

  const setTeam = (teamUser, isChecked) => {
    const selectedUser = user;
    dispatch(setAllGroupUser([]));

    if (isChecked) {
      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    } else {
      teamUser.users.forEach((element) => {
        dispatch(setGroupUser(element));
      });

      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    }
  };

  const setUnassingUser = (teamUser, isChecked) => {
    const selectedUser = user;
    dispatch(setAllGroupUser([]));

    if (isChecked) {
      selectedUser.forEach((element) => {
        if (element.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    } else {
      teamUser.forEach((element) => {
        dispatch(setGroupUser(element));
      });

      selectedUser.forEach((element) => {
        if (element.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    }
  };

  const unAssignedTeam = filteredItems
    .filter((el) => el.active)
    .sort(function (a, b) {
      return a.first_name.localeCompare(b.first_name);
    })
    .sort(
      (a, b) =>
        user.some((element) => element.user_id == b.user_id) -
        user.some((element) => element.user_id == a.user_id)
    )
    .filter((ele) => !ele.team_id);

  return (
    <div>
      {allTeams.map((team, idx) =>
        team ? (
          // <Accordion
          //   sx={{
          //     boxShadow:
          //       "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          //   }}>
          //   <AccordionSummary
          //     expandIcon={<ExpandMoreIcon />}
          //     aria-controls="panel1-content"
          //     id="panel1-header"
          //     onClick={(evn) => {
          //       evn.stopPropagation();
          //     }}>
          //     <label class="custom-checkbox">
          //       <input
          //         type="checkbox"
          //         // checked={el.user_id == user.user_id}
          //         checked={team?.users?.every((ele) =>
          //           user.some((element) => element.user_id == ele.user_id)
          //         )}
          //         onClick={(event) => {
          //           event.stopPropagation();
          //           // event.preventDefault();
          //           setTeam(
          //             team,
          //             team?.users?.every((ele) =>
          //               user.some((element) => element.user_id == ele.user_id)
          //             )
          //           );
          //         }}
          //       />
          //       <span class="checkmark"></span>
          //       {team.team}
          //     </label>
          //   </AccordionSummary>
          //   <AccordionDetails sx={{ paddingTop: 0 }}>
          //     {team.users.map((el, index) => (
          //       <MenuItem
          //         key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
          //         onClick={() => {
          //           console.log("team-reducer", el);
          //           dispatch(setGroupUser(el));
          //           // setSearchQuery("");
          //         }}>
          //         <label class="custom-checkbox">
          //           <input
          //             type="checkbox"
          //             id={`user-check-box-${el.user_id}`}
          //             // checked={el.user_id == user.user_id}
          //             checked={user.some(
          //               (element) => element.user_id == el.user_id
          //             )}
          //             onClick={(event) => {
          //               console.log("Paragraph clicked");
          //               event.stopPropagation();
          //             }}
          //           />
          //           <span class="checkmark"></span>

          //           <Avatar
          //             key={`user-avtar-${el.user_id}`}
          //             username={`${el.first_name} ${el.last_name}`}
          //             user_image={el.user_image}
          //             title={`${el.first_name} ${el.last_name}`}
          //             className="user-avtar-dropdown user-avatar mr-8"
          //             style={{
          //               bgcolor: el?.profile_color?.background,
          //               color: el?.profile_color?.text,
          //               minWidth: "24px !important",
          //               width: "24px !important",
          //               height: "24px !important",
          //             }}>
          //             {createAvtarName(`${el.first_name} ${el.last_name}`)}
          //           </Avatar>

          //           {`${el.first_name} ${el.last_name}`}
          //         </label>
          //       </MenuItem>
          //     ))}
          //   </AccordionDetails>
          // </Accordion>
          <Accordion
            sx={{
              boxShadow:
                "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              disableGutters // Removes padding, allowing custom positioning
              sx={{ display: "flex", alignItems: "center" }}
            >
              {/* Checkbox Area, separated from default toggle area */}
              <Box onClick={(event) => event.stopPropagation()}>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={team?.users?.every((ele) =>
                      user.some((element) => element.user_id === ele.user_id)
                    )}
                    onClick={(event) => {
                      event.stopPropagation(); // Prevents checkbox click from toggling accordion
                      setTeam(
                        team,
                        team?.users?.every((ele) =>
                          user.some(
                            (element) => element.user_id === ele.user_id
                          )
                        )
                      );
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography>{team.team}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              {team.users.map((el, index) => (
                <MenuItem
                  key={`user-avatar-dropdown-admin-assign-${el.user_id}`}
                  onClick={() => {
                    console.log("team-reducer", el);
                    dispatch(setGroupUser(el));
                  }}
                >
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      id={`user-check-box-${el.user_id}`}
                      checked={user.some(
                        (element) => element.user_id === el.user_id
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    <span className="checkmark"></span>
                    <Avatar
                      key={`user-avatar-${el.user_id}`}
                      username={`${el.first_name} ${el.last_name}`}
                      user_image={el.user_image}
                      title={`${el.first_name} ${el.last_name}`}
                      className="user-avatar-dropdown user-avatar mr-8"
                      style={{
                        bgcolor: el?.profile_color?.background,
                        color: el?.profile_color?.text,
                        minWidth: "24px !important",
                        width: "24px !important",
                        height: "24px !important",
                      }}
                    >
                      {createAvtarName(`${el.first_name} ${el.last_name}`)}
                    </Avatar>
                    {`${el.first_name} ${el.last_name}`}
                  </label>
                </MenuItem>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          ""
        )
      )}
      {unAssignedTeam.length > 0 && (
        <Accordion
          sx={{
            boxShadow:
              "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                // checked={el.user_id == user.user_id}
                checked={unAssignedTeam?.every((ele) =>
                  user.some((element) => element.user_id == ele.user_id)
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  setUnassingUser(
                    unAssignedTeam,
                    unAssignedTeam?.every((ele) =>
                      user.some((element) => element.user_id == ele.user_id)
                    )
                  );
                }}
              />
              <span class="checkmark"></span>
              Unassigned Users
            </label>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            {unAssignedTeam.map((el, index) => (
              <MenuItem
                key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
                onClick={() => {
                  console.log("team-reducer", el);
                  dispatch(setGroupUser(el));
                  // setSearchQuery("");
                }}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`user-check-box-${el.user_id}`}
                    // checked={el.user_id == user.user_id}
                    checked={user.some(
                      (element) => element.user_id == el.user_id
                    )}
                    onClick={(event) => {
                      console.log("Paragraph clicked");
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>

                  <Avatar
                    key={`user-avtar-${el.user_id}`}
                    username={`${el.first_name} ${el.last_name}`}
                    user_image={el.user_image}
                    title={`${el.first_name} ${el.last_name}`}
                    className="user-avtar-dropdown user-avatar mr-8"
                    style={{
                      bgcolor: el?.profile_color?.background,
                      color: el?.profile_color?.text,
                      minWidth: "24px !important",
                      width: "24px !important",
                      height: "24px !important",
                    }}
                  >
                    {createAvtarName(`${el.first_name} ${el.last_name}`)}
                  </Avatar>

                  {`${el.first_name} ${el.last_name}`}
                </label>
              </MenuItem>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
