import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import DialogContent from "@mui/material/DialogContent";
import { Drawer } from "@mui/material";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import mobileArrow from "../../../../styles/svg/mobileArrow.svg";
import mobileDots from "../../../../styles/svg/mobileDots.svg";
import BasicGrid from "./detailGrid";
import "./details.scss";
import { useCard } from "../../../../utils/reactQuery/cards";
import Loader from "../../../../components/Loader";
import ChangeStatus from "./components/ChangeStatus";
import AttachFile from "./components/changeBoard/attachFile/AttachFile";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChangeBoard from "./components/changeBoard/ChangeBoard";
import DeleteCard from "../Kanban/DeleteCard";
import { clearCheckedIds } from "../../../../redux/CheckboxSclice";
import { useResponsive } from "../../../../hooks/useResponsive";
import { descriptionUpdate } from "../../../../sevices/apiDescription";
import { useRemoveMultipleParams } from "../../../../hooks/useRemoveMultipleParams";
import DependsOnDropdown from "./sidebar/components/dependsOnDropdown";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

export default function CustomizedDialogs({
  boardNameTitle,
  data,
  cardId,
  boardId,
  showDetails,
  setShowDetails,
  cardsOfSameWorkflowStepId,
  cardsOnBoard,
}) {
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  const [removeMultiParams] = useRemoveMultipleParams();
  const [ishovered, setIsHovered] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const descRef = React.useRef("");
  const ref = React.useRef(false);
  const [isFocus, setIsFocus] = useState(false);
  const isMobile = useResponsive("down", "md");
  const isBetween700And900 = useResponsive("between", 700, 900);
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const cardDetail = card?.data?.data?.responseData;
  const dispatch = useDispatch();
  useEffect(() => {
    if (cardId && cardsOfSameWorkflowStepId.length > 0) {
      const index = cardsOfSameWorkflowStepId.findIndex(
        (item) => item.card.card_id == cardId
      );
      setCurrentCardIndex(index !== -1 ? index : null);
    }
  }, [cardId, cardsOfSameWorkflowStepId]);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const accessForDeleteCard =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId();

  const conditionCheck =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const disableCard =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId();

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      const newIndex = currentCardIndex - 1;

      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleNext = () => {
    if (currentCardIndex < cardsOfSameWorkflowStepId.length - 1) {
      const newIndex = currentCardIndex + 1;

      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleClose = () => {
    if (isFocus) {
      handleSave();
    }
    removeMultiParams(["card_id", "brd_id"]);
    setShowDetails(false);
    dispatch(clearCheckedIds());
  };

  const { mutate: descriptionUpdateMutate } = useMutation({
    mutationFn: descriptionUpdate,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [`card`, cardId] });
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error updating cardDescription", error);
      toast.error(error.response.data.message);
    },
  });

  const handleSave = (text) => {
    descriptionUpdateMutate({
      card_id: cardId,
      data: {
        description: descRef?.current?.value,
      },
    });
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showDetails}
        onMouseEnter={() => setIsHovered(true)} // Set hover state
        onMouseLeave={() => setIsHovered(false)} // Reset hover state
        fullScreen={isMobile}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: isMobile ? "100vh" : "calc(100vh - 30px)",
              borderRadius: isMobile ? "" : "12px",
              margin: isMobile ? "auto" : "15px auto",
              // marginTop: isMobile ? "25px" : "",
              maxWidth: "1180px",
            },
          },
        }}
      >
        {isCardLoading ? (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        ) : (
          <>
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                alignItems: "center",
                backgroundColor: "rgba(242, 246, 254, 1)",
                borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
              }}
              id="customized-dialog-title"
              className={`d-flex header ${
                card?.data?.data?.responseData?.parent_card_id &&
                "parent-card-detail"
              }`}
            >
              {card?.data?.data?.responseData?.parent_card_id && (
                <span
                  className="related_time parent-title-id mb-2 cursor-pointer"
                  onClick={() => {
                    updateSearchParams(
                      "card_id",
                      card?.data?.data?.responseData?.parent_card_id
                    );
                  }}
                >
                  {`${card?.data?.data?.responseData?.board?.key} - ${card?.data?.data?.responseData?.parent_card_id}`}
                </span>
              )}
              <div className="modal-header-container">
                {isMobile && (
                  <img
                    src={mobileArrow}
                    alt=""
                    onClick={handleClose}
                    className="board-owner-avtar-click"
                    style={{ opacity: "1 !important", pointerEvents: "auto" }}
                  />
                )}
                <div
                  className={`${
                    isMobile ? (isBetween700And900 ? "ipad-" : "mobile-") : ""
                  }modal-header-inner-container`}
                >
                  <div
                    className={
                      isMobile
                        ? "mobile-left-heading"
                        : "d-flex align-items-center"
                    }
                  >
                    <div
                      className={
                        isMobile ? "mobile-project-name" : "project-name d-flex"
                      }
                    >
                      <div>
                        <div
                          className={
                            isMobile ? "mobile-project-name" : "project-name"
                          }
                        >
                          {`${card?.data?.data?.responseData?.board?.key} - ${cardId}`}
                        </div>
                      </div>
                    </div>

                    {card && (
                      <div
                        className={
                          card?.data?.data?.responseData?.parent_card_id
                            ? "pt-10"
                            : ""
                        }
                      >
                        {card && cardDetail && cardDetail?.workflow_step_id && (
                          <ChangeStatus
                            currentStatusId={cardDetail.workflow_step_id}
                            cardId={cardId}
                            checkCondition={conditionCheck}
                            card={card}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {isMobile && (
                    <div className="mobile-attachment">
                      <AttachFile cardId={cardId} isMobile={true} />
                    </div>
                  )}
                </div>

                {!isMobile && (
                  <div className="d-flex right-heading">
                    {card && cardDetail && !cardDetail?.card_has_subtask && (
                      <DependsOnDropdown cardId={Number(cardId)} card={card} />
                    )}
                    {card &&
                      !card?.data?.data?.responseData?.card_has_subtask &&
                      !card?.data?.data?.responseData?.parent_card_id && (
                        <ChangeBoard cardId={cardId} card={card} />
                      )}
                    <div className="header-attach-btn">
                      <AttachFile cardId={cardId} />
                    </div>
                    <div>
                      {accessForDeleteCard && (
                        <DeleteCard
                          cardId={cardId}
                          boardId={boardId}
                          setShowDetails={setShowDetails}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </DialogTitle>

            {isMobile ? (
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 9,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={() => {
                  setFilterDrawer(true);
                }}
              >
                <img src={mobileDots} alt="" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogContent dividers sx={{ paddingBottom: "50px !important" }}>
              <Typography gutterBottom>
                <BasicGrid
                  description={card?.data.data.responseData?.description}
                  title={card?.data.data.responseData?.title}
                  cardId={cardId}
                  data={card?.data.data.responseData}
                  descRef={descRef}
                  isfocus={setIsFocus}
                  handleDescriptionSave={handleSave}
                  cardsOnBoard={cardsOnBoard}
                />
              </Typography>
            </DialogContent>
            {ishovered && currentCardIndex > 0 && (
              <IconButton
                aria-label="previous"
                onClick={handlePrevious}
                sx={{
                  position: "absolute",
                  left: 9,
                  bottom: "50%",
                  padding: "12px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "50%",

                  zIndex: 1000,
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            {ishovered &&
              currentCardIndex < cardsOfSameWorkflowStepId.length - 1 && (
                <IconButton
                  aria-label="next"
                  onClick={handleNext}
                  sx={{
                    position: "absolute",
                    right: 9,
                    bottom: "50%",
                    padding: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",

                    zIndex: 1000,
                    transition: "all 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
          </>
        )}
      </BootstrapDialog>
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
          sx={{ zIndex: 1300 }}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              {accessForDeleteCard && (
                <DeleteCard
                  cardId={cardId}
                  boardId={boardId}
                  setShowDetails={setShowDetails}
                />
              )}
              {card && <ChangeBoard cardId={cardId} card={card} />}
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}
