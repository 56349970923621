import React from "react";
import KanbanColumn from "./KanbanColumn";

function KanbanContainor({ workflowSteps, boardName, projects }) {
  console.log("Container", projects, workflowSteps);
  const priorityOrder = {
    Critical: 1,
    High: 2,
    Medium: 3,
    Low: 4,
  };

  return (
    <div className="board-card-scroll-btn d-flex ">
      {workflowSteps.map((column) => (
        <KanbanColumn
          boardName={boardName}
          key={column.workflow_step_id}
          id={column.workflow_step_id}
          name={column.status}
          stage={column.workflow_step_id}
          projects={projects
            .filter(
              (project) =>
                parseInt(project.card.workflow_step_id, 10) ===
                column.workflow_step_id
            )
            .sort(
              (a, b) =>
                priorityOrder[a.card.priority] - priorityOrder[b.card.priority]
            )}
          // onDragEnter={handleOnDragEnter}
          // onDragEnd={handleOnDragEnd}
        />
      ))}
    </div>
  );
}

export default KanbanContainor;
