import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { user } from "../../redux/usersSlice";
import Login from "./Login";
import ChangePassword from "./changePassword";
import { localStorageInstance } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";

const LoginWrapper = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [url, setUrl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (response, url) => {
    const { token, user_data, lastLogin } = response;
    setUrl(url);

    if (!lastLogin) {
      // First login, show "Change Password" screen
      setUserData(user_data);
      setAuthToken(token.access_token);
      setShowChangePassword(true);
    } else {
      // Save user data and token in local storage

      dispatch(user(user_data));
      localStorageInstance.setItem({
        key: "token",
        value: token.access_token,
      });
      localStorageInstance.setItem({
        key: "role",
        value: user_data.role,
      });
      localStorageInstance.setItem({
        key: "userId",
        value: user_data.user_id,
      });
      navigate(url);
    }
  };

  const handlePasswordChangeSuccess = () => {
    // Save user data and token in local storage after successful password change
    // localStorage.setItem("user", JSON.stringify(userData));
    // localStorage.setItem("token", authToken);

    dispatch(user(userData));
    localStorageInstance.setItem({
      key: "token",
      value: authToken,
    });
    localStorageInstance.setItem({
      key: "role",
      value: userData.role,
    });
    localStorageInstance.setItem({
      key: "userId",
      value: userData.user_id,
    });

    setUserData(null);
    setAuthToken(null);
    setShowChangePassword(false);
    navigate(url);
  };

  return (
    <div>
      {!showChangePassword ? (
        <Login onLogin={handleLogin} />
      ) : (
        <ChangePassword
          userData={userData}
          authToken={authToken}
          onSuccess={handlePasswordChangeSuccess}
        />
      )}
    </div>
  );
};

export default LoginWrapper;
