import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import mobileBg from "../styles/svg/login_bg_mobile.svg";
import GraphicElements from "../styles/svg/graphic_elements.svg";
import { useResponsive } from "../hooks/useResponsive";
import { useAssets } from "../utils/reactQuery/domainAssets";
import Loader from "./Loader";

const NotFoundPage = () => {
  const isMobile = useResponsive("down", "md");
  const { isLoading, error, icon, isError, logo } = useAssets();
  const navigate = useNavigate();
  const domainUrl = window.location.hostname;
  const marketingSiteUrl =
    "https://" + domainUrl.split(".").splice(1).join(".") + '?signup=open';
  
   const [timeLeft, setTimeLeft] = useState(10);

   useEffect(() => {
     // Exit early if the time is already 0
     if (timeLeft === 0) {
       window.location.href =
         marketingSiteUrl;
     }

     // Set up a timer that counts down every second
     const timerId = setInterval(() => {
       setTimeLeft((prevTime) => prevTime - 1);
     }, 1000);

     // Cleanup the timer when the component unmounts or when timeLeft updates
     return () => clearInterval(timerId);
   }, [timeLeft]);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  }

  if (!isError) {
    return navigate("/board");
  } else if (error.response?.status != "404" || error.response?.data?.tenant_found) {
    return navigate("/board");
  }

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${isMobile ? mobileBg : GraphicElements})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
        minHeight: "100vh",
      }}
      disableGutters={true}
    >
      <Typography variant="h1" sx={{ fontSize: { xs: "3rem", md: "6rem" } }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Oops... This page is not found.
      </Typography>
      <Typography>
        The page you're looking for couldn't be found. Please double-check the
        URL.
      </Typography>
      <Typography>
        You will be automatically redirected to your SignUp Page in 10 seconds.
        If you prefer to return now, please click the button below....{timeLeft}
      </Typography>
      <Button onClick={() =>  window.location.href =
         marketingSiteUrl}>Go to SignUp Page</Button>
    </Box>
  );
};

export default NotFoundPage;
