import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../sevices/apiAuth";
import GraphicElements from "../../styles/svg/graphic_elements.svg";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { localStorageInstance } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { user } from "../../redux/usersSlice";
import { useResponsive } from "../../hooks/useResponsive";
import mobileBg from "../../styles/svg/login_bg_mobile.svg";
import { useAssets } from "../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../utils/constants";
import TenantSiteName from "../../components/TenantSiteName";

function Login({ onLogin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithEmail, setLoginWith] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "md");
  const { errors } = formState;
  const [searchParams, setSearchParams] = useSearchParams();
  const { logo } = useAssets();
  const apiUrl = getAPIBaseUrl();
  //Current Url
  const url =
    localStorageInstance.getItem("redirectLocation") ||
    searchParams.get("url") ||
    "/board";

  /**
   * Set details to local storage
   */
  const setDetails = (data) => {
    console.log(data);
    dispatch(user(data.user_data));
    localStorageInstance.setItem({
      key: "token",
      value: data.token.access_token,
    });
    localStorageInstance.setItem({
      key: "role",
      value: data.user_data.role,
    });
    localStorageInstance.setItem({
      key: "userId",
      value: data.user_data.user_id,
    });
  };

  function createFormData(data) {
    const formData = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);
    return formData;
  }

  const isEmail = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+!*-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(input);
  };

  const submit = (data) => {
    setApiError("");
    setLoading(true);
    const formData = createFormData(data);

    if (isEmail(data.email)) {
      login(formData, true)
        .then((res) => {
          let data = res.data.data.responseData[0];
          // setDetails(data);
          // navigate(url);
          onLogin({ ...data, lastLogin: data?.user_data?.last_seen },url);
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "ERR_NETWORK") {
            setApiError("An unexpected error occurred. Please try again later");
          } else setApiError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      login(formData, false)
        .then((res) => {
          let data = res.data.data.responseData[0];
          setDetails(data);
          navigate(url);
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "ERR_NETWORK") {
            setApiError("An unexpected error occurred. Please try again later");
          } else setApiError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function onError(errors) {
    console.log(errors);
  }

  function changeLoginWith() {
    reset();
    setLoginWith((loginWith) => !loginWith);
    setApiError("");
  }

  const handleForgotePassword = () => {
    navigate("/forgotpassword");
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${isMobile ? mobileBg : GraphicElements})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
        minHeight: "100vh",
      }}
      disableGutters={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 440,
          justifyContent: "center",
          padding: "40px 15px",
        }}
      >
        {logo ? (
          <img
            src={`${apiUrl}/${logo}`}
            alt="not found"
            width={isMobile ? 251 : ""}
            className="login_logo"
          />
        ) : (
          <TenantSiteName />
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          sx={{
            marginTop: 4,
            padding: isMobile ? "32px 16px" : "40px",
            borderRadius: "16px",
            boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
            background: "#ffffff",
          }}
        >
          <Typography
            className="text-center black-primary-color"
            component={"h1"}
            variant="h5"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            Welcome to Task Board
          </Typography>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            // label={loginWithEmail ? "Email Address" : "Username"}
            label={"Username / Email"}
            name="email"
            error={errors?.email ? true : false}
            helperText={errors?.email && errors.email.message}
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: "Email is required",
            })}
            className="primary-input-field"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={errors?.password ? true : false}
            helperText={errors?.password && errors.password.message}
            autoComplete="current-password"
            {...register("password", {
              required: "Password is required",
            })}
          />

          <p
            className="card-anchor forgot-password-div"
            onClick={handleForgotePassword}
          >
            Forgot Password
          </p>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue"
            sx={{ mt: "14px", padding: "13px 16px" }}
            disabled={loading}
          >
            Sign In
          </Button>
          {/* <Divider sx={{ mt: "24px", mb: "24px", color: "#919EAB" }}>
            OR
          </Divider>
          <Button
            fullWidth
            variant="outlined"
            className="btn-secondary-border"
            sx={{
              padding: "13px 16px",
            }}
            onClick={changeLoginWith}>
            {loginWithEmail ? "Login with LDAP" : "Login with Client"}
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
