import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import Avatar from "../../../../components/Avatar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { assignCard, updateCard } from "../../../../sevices/apiCards";
import NotAssignCardIcon from "../../../../styles/svg/unassigned.svg";
import FormDialog from "../../../../components/Dialog/FormDialog";
import { useBoardUsers } from "../../../../utils/reactQuery/users";
import { createAvtarName, hasImage } from "../../../../utils/helpers";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import {
  currentUserId,
  getAPIBaseUrl,
  isAdmin,
} from "../../../../utils/constants";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import CommentForm from "../../../../components/CommentPopUp";

function ListAssignCard({
  tooltipName,
  cardId,
  name,
  cardData,
  callback: refetch,
  listView,
}) {
  const { id: board_id } = useParams();
  const boardId = board_id || cardData?.board_id;
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [assignCardLoading, setAssignCardLoading] = useState(false);
  const open = Boolean(anchorEl);
  const { isLoading: boardLoading, data: boardUsers } = useBoardUsers(boardId);
  const [milestone] = useCustomSearchParams("milestone");
  const currentUserIdVariable = currentUserId();
  const { boardName } = useBoardName(boardId);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;

  const accessForAssign =
    isAdmin() ||
    boardOwnerId === currentUserIdVariable ||
    cardData?.assigned_to_user_id === currentUserIdVariable ||
    cardData?.reporter === currentUserIdVariable;

  useEffect(() => {
    const sortedUsers = boardUsers?.data?.data?.responseData
      ? boardUsers?.data?.data?.responseData.sort(function (
          prevValue,
          nextValue
        ) {
          return prevValue.username.localeCompare(nextValue.username);
        })
      : [];
    const filteredUser = sortedUsers.filter(
      (user) => user.user_id == cardData?.card?.assigned_to_user_id
    );
    let updatedUsers = sortedUsers;
    if (filteredUser.length) {
      updatedUsers = sortedUsers.filter(
        (userData) => userData.user_id !== cardData?.card?.assigned_to_user_id
      );
      updatedUsers.unshift(filteredUser[0]);
    }
    setUsers(updatedUsers);
  }, [boardLoading, boardId, boardUsers, cardData]);

  const handleAvatarClick = (event) => {
    event.stopPropagation();
    if (accessForAssign) setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  function getInitials(name) {
    // Split the name by spaces
    const words = name.trim().split(" ");

    // Get the first letter of each word
    const initials = words.map((word) => word[0].toUpperCase()).join("");

    return initials;
  }
  const { mutate: updateCardmutate } = useMutation({
    mutationFn: assignCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, cardData?.board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "mineCards", // Match only queries that start with 'user'
      });
      toast.success(res.data.message);
      setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const assignUser = (id) => {
    setOpenDialog(true);
    setUserId(id);
  };
  const callback = (event) => {
    event.stopPropagation();
    setAssignCardLoading(true);
    updateCardmutate({
      card_id: cardData?.card_id,
      data: {},
    });
  };

  const closeConfirmation = (event) => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleAssignClick = () => {
    updateCardmutate({
      card_id: cardId,
      data: {
        assigned_to_user_id: currentUserIdVariable,
      },
    });
  };
  return (
    <>
      {userId?.user_id === "null" && openDialog && (
        <ConfirmationDialog
          open={openDialog}
          handleClose={closeConfirmation}
          message={`Are you sure you want to unassigned this ticket`}
          callback={callback}
          assignCardLoading={assignCardLoading}
          btnTitle={"Unassign"}
          title={"Unassign Card"}
        />
      )}
      {openDialog && userId?.user_id !== "null" && (
        <FormDialog
          open={openDialog}
          handleClose={closeConfirmation}
          title={`Add a Note for ${userId.username}`}
          width="657px"
        >
          <CommentForm
            cardId={cardData?.card_id}
            userId={userId.user_id}
            onClose={() => setOpenDialog(false)}
          />
        </FormDialog>
      )}
      <div className="d-flex align-items-center">
        <div onClick={handleAvatarClick}>
          {cardData?.assigned_to_user_id ? (
            <Avatar
              title={tooltipName}
              className={"card-avtar header-avatar"}
              user_image={cardData?.assigned_to_user?.user_image}
              style={
                !hasImage(cardData?.assigned_to_user?.user_image) && {
                  bgcolor:
                    cardData?.assigned_to_user?.profile_color?.background,
                  color: cardData?.assigned_to_user?.profile_color?.text,
                }
              }
            >
              {getInitials(name)}
            </Avatar>
          ) : (
            <>
              <Avatar
                unassigned={true}
                title={"Unassigned"}
                className={"card-avtar header-avatar"}
                user_image={NotAssignCardIcon}
              />
            </>
          )}
        </div>

        <div
          className={`${
            listView ? `assignee-name listView-fontColor` : `assignee-name`
          }`}
        >
          {cardData?.assigned_to_user && (
            <p
              className={`${
                listView ? `assignee-name listView-fontColor` : `assignee-name`
              }`}
            >{`${cardData?.assigned_to_user?.first_name} ${cardData?.assigned_to_user?.last_name}`}</p>
          )}
        </div>
        <div className="sidebar-assigntome-btn">
          {cardData?.assigned_to_user ? null : (
            <Button onClick={handleAssignClick} sx={{}}>
              Assign to me
            </Button>
          )}
        </div>
      </div>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        <MenuItem
          onClick={() =>
            assignUser({
              user_id: "null",
              username: "unassigned",
            })
          }
        >
          None
        </MenuItem>
        {users.map((userData) => {
          return (
            <MenuItem
              key={`user-avtar-dropdown-card-assign-${userData.user_id}`}
              onClick={() => assignUser(userData)}
              className={
                cardData?.assigned_to_user_id &&
                cardData.assigned_to_user_id == userData.user_id
                  ? "active-assign"
                  : ""
              }
            >
              <Avatar
                key={`user-avtar-${userData.user_id}`}
                username={userData.username}
                user_image={userData.user_image}
                title={userData.username}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(userData.user_image) && {
                    bgcolor: userData?.profile_color?.background,
                    color: userData?.profile_color?.text,
                  }
                }
              >
                {createAvtarName(userData.username)}
              </Avatar>

              {userData.username}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default ListAssignCard;
