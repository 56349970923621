import React from "react";
import RemoveStep from "../RemoveStep";
import DraggableField from "./DraggableField";
const WorkflowSteps = ({
  edit,
  register,
  errors,
  fields,
  remove,
  close,
  control,
}) => {
  const [openRemoveStep, setOpenRemoveStep] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState();

  return (
    <>
      {openRemoveStep && (
        <RemoveStep
          close={close}
          currentStep={currentStep}
          fields={fields}
          showRemoveSteps={openRemoveStep}
          setShowRemoveSteps={setOpenRemoveStep}
        />
      )}
      {fields &&
        fields.map((field, index) => (
          <DraggableField
            field={field}
            control={control}
            index={index}
            remove={remove}
            edit={edit}
            setOpenRemoveStep={setOpenRemoveStep}
            register={register}
            setCurrentStep={setCurrentStep}
            errors={errors}
          />
        ))}
    </>
  );
};

export default WorkflowSteps;
