import { Navigate, Outlet, useLocation } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import Loader from "../../components/Loader";
import { useAssets } from "../../utils/reactQuery/domainAssets";

const Protected = () => {
  const location = useLocation();
  const { isLoading, error, icon, isError, logo } = useAssets();
  console.log("error in useAssets == ", isError, error)
  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  }

  if (
    isError  && error.response?.status == "404" &&
    !error.response?.data?.tenant_found
  ) {
    return <Navigate to="/not-found" />;
  }
  const auth =
    localStorageInstance.getItem("token") &&
    localStorageInstance.getItem("userId");

  if (location.pathname === "/resetpassword/") {
    localStorageInstance.clear();
    return <Outlet />;
  }
  // const isAdmin = localStorage.getItem("isAdmin") === "Admin";
  return auth ? <Navigate to="/board" /> : <Outlet />;
};

export default Protected;
