import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import { Auth } from "./Auth/Auth";
import { Pages } from "./Pages/Pages";
import NotFoundPage from "../components/NotFound";
// import { localStorageInstance } from "../utils/localStorage";
// import { useLoginUser } from "../utils/reactQuery/users";
// import { useDispatch } from "react-redux";
// import { user } from "../redux/usersSlice";
const notFound = {
          path: "/not-found",
          element: <NotFoundPage/>
        }

const router = createBrowserRouter([Auth, Pages, notFound]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
